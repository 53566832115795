import React, { FunctionComponent } from "react";

// adapted from pushpin.svg in primaries
export const PushpinIcon: FunctionComponent<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg
        version="1.1"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M33.9858,39.98h-3.9769l-5.73312e-08,-3.45466e-10c-0.555202,-0.00334555 -1.00799,0.444022 -1.01134,0.999224c-9.55879e-05,0.0158631 0.000184309,0.0317262 0.000839456,0.047576l0.09,1.8926l8.1077e-07,1.69924e-05c0.224947,4.71451 0.713306,9.41275 1.46279,14.0727l-3.37575e-08,-8.77512e-08c0.307198,0.798549 1.20358,1.19687 2.00213,0.889668c0.409051,-0.15736 0.732308,-0.480618 0.889668,-0.889668l1.85145e-06,-1.15151e-05c0.74926,-4.66004 1.23769,-9.35829 1.463,-14.0728l0.09,-1.8926l-9.29513e-09,2.2352e-07c0.0230619,-0.554567 -0.407808,-1.02283 -0.962375,-1.04589c-0.0159302,-0.000662462 -0.0318738,-0.000945741 -0.0478175,-0.000849598Zm5.9856,-11.717l1.33361e-07,4.48278e-08c-0.58324,-0.196049 -0.99529,-0.718529 -1.05,-1.3314l-0.8205,-10.667l-4.90502e-10,-6.9529e-09c-0.0497118,-0.704677 0.367623,-1.35886 1.0276,-1.6108l-6.42663e-07,2.67145e-07c2.01747,-0.838631 3.67436,-2.36219 4.6789,-4.3024l2.26452e-08,-4.19035e-08c0.128914,-0.238548 0.195862,-0.505649 0.1947,-0.7768v0c0,-0.878133 -0.711867,-1.59 -1.59,-1.59c0,0 0,0 0,0h-20.8193h-6.95011e-08c-0.878133,3.83844e-08 -1.59,0.711867 -1.59,1.59l7.33895e-09,-1.713e-06c-0.00116183,0.271151 0.0657851,0.538252 0.194699,0.7768l-8.24693e-07,-1.59257e-06c1.00466,1.94011 2.66151,3.46364 4.6789,4.3024l1.00815e-08,3.848e-09c0.660013,0.251921 1.07742,0.906088 1.0278,1.6108l-0.82,10.667l6.01455e-08,-6.73762e-07c-0.0547098,0.612871 -0.46676,1.13535 -1.05,1.3314c-2.4539,0.8713 -6.0307,3.2637 -6.0307,7.7171v0.4093l2.13163e-14,-2.40083e-07c-1.32594e-07,0.878133 0.711867,1.59 1.59,1.59c0,0 3.55271e-15,0 3.55271e-15,0h24.8183l-6.95011e-08,-7.10543e-15c0.878133,3.83844e-08 1.59,-0.711867 1.59,-1.59c0,0 0,-7.10543e-15 0,-7.10543e-15v-0.409398c0.0005,-4.4533 -3.5765,-6.8457 -6.0304,-7.717Z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);
