import React, { FunctionComponent } from "react";
import { Trans } from "react-i18next";
import { useUserInfo } from "../../../providers/user-info-provider";
import { StandardHeader } from "./standard-header";

export const ReadOnlyHeader: FunctionComponent = () => {
    const { loggedIn, readOnly } = useUserInfo();

    return loggedIn && readOnly ? (
        <StandardHeader>
            <Trans
                parent="div"
                className="prose mx-auto"
                i18nKey="client:header-notice.read-only"
            >
                <p>
                    <strong>cohost is read-only as of October 1, 2024.</strong>{" "}
                    The site will be fully shut down at the end of the year.
                    Until then, you can use the site but can&rsquo;t do anything
                    that isn&rsquo;t reading (following, liking posts, making or
                    editing posts, sending asks, updating your profile, etc).
                </p>
                <p>
                    thank you for having used cohost. we&rsquo;ll see you
                    around.{" "}
                    {/* not using the custom emoji component because getting the emoji URLs was causing weird load issues. it's fine for this imo. */}
                    <img
                        src="https://cohost.org/static/17aa2d48956926005de9.png"
                        alt=":eggbug:"
                        title=":eggbug:"
                        className="m-0 inline-block aspect-square object-contain align-middle"
                        style={{ height: "var(--emoji-scale, 1em)" }}
                    ></img>
                </p>
            </Trans>
        </StandardHeader>
    ) : null;
};
