import React, { FC } from "react";
import {
    useRequiresLogin,
    useUserInfo,
} from "../../providers/user-info-provider";
import { trpc } from "@/client/lib/trpc";
import { useDynamicTheme } from "../../hooks/dynamic-theme";
import { Helmet } from "react-helmet-async";
import { DateTime } from "luxon";
import { useDisplayPrefs } from "../../hooks/use-display-prefs";
import { UnfriendlyTimestamp } from "../unfriendly-timestamp";

export const DataExportsPage: FC = () => {
    useRequiresLogin();

    const [dataExports] =
        trpc.users.dataExports.listDataExports.useSuspenseQuery(undefined, {
            refetchOnReconnect: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        });

    const { email } = useUserInfo();
    const { beatsTimestamps } = useDisplayPrefs();

    const theme = useDynamicTheme();

    return (
        <div className="co-themed-box co-static" data-theme={theme.current}>
            <Helmet title="data exports" />
            <div className="co-prose prose">
                <h1>Data Exports</h1>
                <p>
                    Below is a list of data exports we have created for your
                    account, as well as a link to download them. The links below
                    will work for {beatsTimestamps ? "1000 beats" : "24 hours"}.
                    If you need a new one, you can refresh the page.
                </p>
                <p>
                    <b>
                        Data exports will be available through the end of 2024.
                        All data exports will be deleted at the time that cohost
                        goes offline.
                    </b>
                </p>
                {dataExports.length ? (
                    <table>
                        <thead>
                            <th>timestamp</th>
                            <th>link</th>
                        </thead>
                        <tbody>
                            {dataExports.map((dataExport) => (
                                <tr key={dataExport.exportId}>
                                    <td>
                                        <UnfriendlyTimestamp
                                            dateISO={dataExport.createdAt}
                                            className=""
                                            formatOpts={DateTime.DATETIME_MED}
                                        />
                                    </td>
                                    <td>
                                        <a href={dataExport.signedUrl}>
                                            download
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>
                        You don't currently have any data exports available! You
                        will receive an e-mail at {email} once you do.
                    </p>
                )}
            </div>
        </div>
    );
};
