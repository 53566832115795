import React, { FunctionComponent } from "react";

export const Kiki: FunctionComponent<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg
        viewBox="0 0 500 442"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M214.831 81.3559 107.203 10.5932l38.56 131.3558L0 180.932l133.475 35.17-125.42415 225L198.729 305.932l67.796 125.848 2.967-163.983 183.05 101.695-145.339-155.085L500 116.102l-240.254 19.915L294.492 0l-79.661 81.3559Z"
            fill="currentColor"
        />
    </svg>
);
