/**
 * Post Blocks
 *
 * Specifies all the types for the block-based Post system.
 * @module
 */

import { z } from "zod";
import { WireAskModel } from "./asks";
import { WireImageAttachmentMetadata } from "./attachments";
import { AttachmentId } from "./ids";

/**
 * @internal
 */
const BaseBlock = z.object({
    type: z.string(),
});
interface BaseBlock {
    type: string;
}

/**
 * @category Storage Blocks
 *
 * Blocks as they are stored in the database.
 * Only contains minimal information needed to reproduce content, used as the base to generate [[View Blocks]].
 */

/**
 * @category Storage Blocks
 */
export const MarkdownStorageBlock = BaseBlock.extend({
    type: z.literal("markdown"),
    markdown: z.object({
        /** Raw markdown to be parsed at render-time. */
        content: z.string(),
    }),
});
export type MarkdownStorageBlock = z.infer<typeof MarkdownStorageBlock>;

/**
 * @category Storage Blocks
 */
export const AttachmentStorageBlock = BaseBlock.extend({
    type: z.literal("attachment"),
    attachment: z.object({
        /** ID for the [[`Attachment`]] to be rendered. */
        attachmentId: AttachmentId,
        altText: z.string().optional(),
    }),
});
export type AttachmentStorageBlock = z.infer<typeof AttachmentStorageBlock>;

export const InvalidAttachmentStorageBlock = AttachmentStorageBlock.extend({
    attachment: AttachmentStorageBlock.shape.attachment.extend({
        attachmentId: z.null(),
    }),
});
export type InvalidAttachmentStorageBlock = z.infer<
    typeof InvalidAttachmentStorageBlock
>;

export const AttachmentRowStorageBlock = BaseBlock.extend({
    type: z.literal("attachment-row"),
    attachments: z.array(AttachmentStorageBlock),
});
export type AttachmentRowStorageBlock = z.infer<
    typeof AttachmentRowStorageBlock
>;

/**
 * Union type used on the [[`Post`]] model
 *
 * @category Storage Blocks
 */
export const StorageBlock = z.union([
    MarkdownStorageBlock,
    AttachmentStorageBlock,
    AttachmentRowStorageBlock,
]);
export type StorageBlock = z.infer<typeof StorageBlock>;

/**
 * @category View Blocks
 * View Blocks _must_ contain all data needed to render the block.
 * This is a wire-safe type and as a result _must_ not contain anything the client can't see.
 *
 */

/**
 * No changes are currently required from the [[`MarkdownStorageBlock`]]
 * so this is currently a simple alias.
 *
 * @category View Blocks
 * */
export const MarkdownViewBlock = MarkdownStorageBlock.extend({});
export type MarkdownViewBlock = z.infer<typeof MarkdownViewBlock>;

/**
 * Adds the image URL for rendering
 *
 * @category View Blocks
 */
const ImageAttachmentViewModel = AttachmentStorageBlock.shape.attachment
    .extend({
        previewURL: z.string(),
        fileURL: z.string(),
        kind: z.literal("image"),
        width: z.number().nullish(),
        height: z.number().nullish(),
    })
    .extend(WireImageAttachmentMetadata.shape);

const AudioAttachmentViewModel = AttachmentStorageBlock.shape.attachment.extend(
    {
        previewURL: z.string(),
        fileURL: z.string(),
        kind: z.literal("audio"),
        artist: z.string().optional(),
        title: z.string().optional(),
    }
);

const AttachmentViewModel = z.discriminatedUnion("kind", [
    ImageAttachmentViewModel,
    AudioAttachmentViewModel,
]);

export const AttachmentViewBlock = AttachmentStorageBlock.extend({
    attachment: AttachmentViewModel,
});

export type AttachmentViewBlock = z.infer<typeof AttachmentViewBlock>;

export const AttachmentRowViewBlock = AttachmentRowStorageBlock.extend({
    attachments: z.array(AttachmentViewBlock),
});
export type AttachmentRowViewBlock = z.infer<typeof AttachmentRowViewBlock>;

/**
 * NOTE: AskViewBlock DOES NOT have a corresponding storage block. It is
 * generated by the server.
 */
export const AskViewBlock = BaseBlock.extend({
    type: z.literal("ask"),
    ask: WireAskModel,
});
export type AskViewBlock = z.infer<typeof AskViewBlock>;

/**
 * Union type used for [[`PostViewModel`]] and component renderers.
 * @category View Blocks
 */
export const ViewBlock = z.union([
    MarkdownViewBlock,
    AttachmentViewBlock,
    AskViewBlock,
    AttachmentRowViewBlock,
]);
export type ViewBlock = z.infer<typeof ViewBlock>;

export function isAttachmentViewBlock(
    test: unknown
): test is AttachmentViewBlock {
    return AttachmentViewBlock.safeParse(test).success;
}

export function isMarkdownViewBlock(test: unknown): test is MarkdownViewBlock {
    return MarkdownViewBlock.safeParse(test).success;
}

export function isAskViewBlock(test: unknown): test is AskViewBlock {
    return AskViewBlock.safeParse(test).success;
}

export function isAttachmentRowViewBlock(
    test: unknown
): test is AttachmentRowViewBlock {
    return AttachmentRowViewBlock.safeParse(test).success;
}

export function isAttachmentStorageBlock(
    test: unknown
): test is AttachmentStorageBlock {
    return AttachmentStorageBlock.safeParse(test).success;
}

export function isAttachmentRowStorageBlock(
    test: unknown
): test is AttachmentRowStorageBlock {
    return AttachmentRowStorageBlock.safeParse(test).success;
}

export function isMarkdownStorageBlock(
    test: unknown
): test is MarkdownStorageBlock {
    return MarkdownStorageBlock.safeParse(test).success;
}

// via https://github.com/colinhacks/zod/issues/627#issuecomment-911679836
// could be worth investigating a factory function?
export function parseAttachmentViewBlocks(originalBlocks: unknown[]) {
    return z
        .preprocess(
            (blocks) =>
                z.array(z.any()).parse(blocks).filter(isAttachmentViewBlock),
            z.array(AttachmentViewBlock)
        )
        .parse(originalBlocks);
}

export function summaryContent(block: ViewBlock): string {
    switch (block.type) {
        case "markdown":
            return block.markdown.content;
        case "attachment": {
            const encodedFilename = block.attachment.fileURL.split("/").pop();

            return encodedFilename
                ? `[${block.attachment.kind}: ${decodeURIComponent(
                      encodedFilename
                  )}]`
                : `[${block.attachment.kind}]`;
        }
        case "attachment-row":
            return block.attachments
                .map((attachment) => summaryContent(attachment))
                .join("\n");
        case "ask": {
            const askerString = block.ask.anon
                ? block.ask.loggedIn
                    ? "Anonymous User asked:"
                    : "Anonymous Guest asked:"
                : `@${block.ask.askingProject.handle} asked:`;
            return `${askerString}
> ${block.ask.content.split("\n").join("\n> ")}`;
        }
    }
}

export function getAttachmentViewBlocks(
    blocks: ViewBlock[]
): AttachmentViewBlock[] {
    return blocks.reduce<AttachmentViewBlock[]>((blocks, block) => {
        if (isAttachmentViewBlock(block)) {
            return [...blocks, block];
        }

        if (isAttachmentRowViewBlock(block)) {
            return [...blocks, ...block.attachments];
        }

        return blocks;
    }, []);
}
