import React, { FunctionComponent } from "react";

export const CohostEggBookman: FunctionComponent<
    React.SVGAttributes<SVGElement>
> = (props) => (
    <svg
        viewBox="0 0 69 25"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        {...props}
        style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinejoin: "round",
            strokeMiterlimit: 2,
        }}
    >
        <path
            d="m275.723 316.744 3.445 10.118c-1.844 7.478-3.758 12.916-5.742 16.315-1.985 3.399-4.11 5.099-6.375 5.099-3.625 0-6.653-3.948-9.082-11.844-2.43-7.896-3.645-17.518-3.645-28.865 0-7.687.566-14.72 1.699-21.099 1.133-6.38 2.688-11.347 4.664-14.903 1.977-3.556 4.309-5.334 6.996-5.334 3.219 0 5.848 2.497 7.887 7.491 2.039 4.994 3.059 10.262 3.059 15.805 0 2.353-.16 4.458-.481 6.314-.32 1.856-.808 3.36-1.465 4.51-.656 1.15-1.351 1.726-2.085 1.726-.594 0-1.125-.393-1.594-1.177-.469-.784-.86-1.778-1.172-2.981-.141-.522-.414-2.143-.82-4.863-.516-3.399-.914-5.647-1.196-6.745-.281-1.098-.617-1.948-1.008-2.549-.39-.602-.835-.902-1.335-.902-.813 0-1.59.876-2.333 2.627-.742 1.752-1.351 4.406-1.828 7.962-.476 3.555-.714 7.529-.714 11.922 0 4.811.281 9.138.843 12.981.563 3.844 1.317 6.759 2.262 8.746.945 1.987 1.988 2.98 3.129 2.98 1.094 0 2.195-.993 3.305-2.98 1.109-1.987 2.304-5.438 3.586-10.354ZM295.223 266.231c2.671 0 5 1.752 6.984 5.255 1.984 3.504 3.512 8.275 4.582 14.315 1.07 6.04 1.605 13.112 1.605 21.217 0 12.236-1.187 22.171-3.562 29.806-2.375 7.634-5.547 11.452-9.516 11.452-4.047 0-7.265-3.844-9.656-11.531-2.312-7.32-3.469-17.229-3.469-29.727 0-8.21.528-15.269 1.582-21.178 1.055-5.909 2.582-10.654 4.582-14.236 2-3.582 4.289-5.373 6.868-5.373Zm.093 17.178c-.89 0-1.726.889-2.508 2.667-.781 1.777-1.386 4.392-1.816 7.843-.43 3.451-.644 7.844-.644 13.178 0 5.49.21 10.039.632 13.647.422 3.609 1.004 6.275 1.746 8.001.743 1.726 1.559 2.588 2.45 2.588 1.375 0 2.554-1.947 3.539-5.843.984-3.896 1.476-9.948 1.476-18.158 0-7.948-.457-13.923-1.371-17.923-.914-4-2.082-6-3.504-6ZM318.355 318.47c1.203 0 2.231 1.425 3.082 4.275.852 2.849 1.278 6.288 1.278 10.314s-.426 7.464-1.278 10.314c-.851 2.85-1.879 4.275-3.082 4.275-1.218 0-2.254-1.425-3.105-4.275-.852-2.85-1.277-6.288-1.277-10.314s.425-7.465 1.277-10.314c.851-2.85 1.887-4.275 3.105-4.275Z"
            style={{
                fillRule: "nonzero",
                fill: "currentcolor",
            }}
            transform="matrix(1 0 0 .2988 -254.324 -79.5522)"
        />
    </svg>
);
